import React from "react";
import logo from "./images/keh2.png";
import Button from "@material-ui/core/Button";
import "./App.css";
import MainForm from "./MainForm";
import Container from "@material-ui/core/Container";

function App() {
  return (
    <div className="App">
      <header className="App-header" id={"appHeader"}>
        <img src={logo} className="App-logo" alt="logo" />
        <div id="google_translate_element"></div>
        {/* <div style={{ width: "75%", backgroundColor: "white", padding: 10 }}> */}

        {/* </div> */}
      </header>
      <Container>
        <MainForm />
      </Container>
    </div>
  );
}

export default App;
