import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Divider from "@material-ui/core/Divider";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { makeStyles } from '@material-ui/core/styles';

import Oakland from "./images/oakland.jpg";
import Emeryville from "./images/emeryville.png";
import Hayward from "./images/hayward.jpg";



import { AssignmentLateSharp } from "@material-ui/icons";


const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

// import atlas from 'azure-maps-rest'
// // Get an Azure Maps key at https://azure.com/maps.
// var subscriptionKey = 'FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y';

// // Use SubscriptionKeyCredential with a subscription key.
// var subscriptionKeyCredential = new atlas.SubscriptionKeyCredential(subscriptionKey);



// // Use subscriptionKeyCredential to create a pipeline.
// var pipeline = atlas.MapsURL.newPipeline(subscriptionKeyCredential, {
//   retryOptions: { maxTries: 4 } // Retry options
// });

// // Create an instance of the SearchURL client.
// var searchURL = new atlas.SearchURL(pipeline);

const raceChoices = [
  "American Indian or Alaska Native",
  "Asian",
  "Black or African American",
  "Hawaiian Native or Pacific Islander",
  "Multi-racial",
  "Caucasian/White",
  "Hispanic/Latino",
];

const genderIdentityChoices = [
  "Male/Man",
  "Female/Woman",
  "Trans Male/Trans Man",
  "Trans Female/Trans Woman",
  "Gender queer/Gender Non-confirming",
  "I’d prefer not to say"
];

const sexualOrientationChoices = [
  "Bisexual",
  "Gay / Lesbian / Same-Gender Loving​",
  "Questioning / Unsure",
  "Straight / Heterosexual",
  "Not listed",
  "I'd prefer not to say"
];

const formatUSNumber = (entry = "") => {
  if (!entry || entry.length < 1) return entry;
  const match = entry
    .replace(/\D+/g, "")
    .replace(/^1/, "")
    .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
  const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
  const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : "";
  return `${part1}${part2}${part3}`;
};


export default function AddressForm(data) {
  
const classes = useStyles();
  const findAddress = async (input) => {
  const subscriptionKey = 'FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y';



  fetch(`https://atlas.microsoft.com/search/address/json?subscription-key=FKNIAeFQ1u2emlgfmjchT8CLrBLesFNwhQq3muDkP_Y&countrySet=US&api-version=1.0&query=${input}`).then(res => {
      return res.json()
    }).then(res => {
        
        console.log('res', res);
        const sA = res.results && res.results.length >= 1 ? res.results[0].address : null;

        const caResults = res.results.filter(r=>r.address.countryCode == 'US' && r.address.countrySubdivision == 'CA');
        console.log('caResults', caResults);
        
        if (caResults.length > 0) {
          console.log('suggested address', sA, res);
          // const sug = {...sA};
          //need to complete map to return only fields needed, street address, city and zip
          data.setSuggestedAddress({ ...data.suggestedAddress, address: caResults.map(r=>{return{value: `${r.address.streetNumber} ${r.address.streetName}`, inputValue: `${r.address.streetNumber} ${r.address.streetName}`, country: r.address.country, countryCode: r.address.countryCode, countrySecondarySubdivision: r.address.countrySecondarySubdivision, countrySubdivision: r.address.countrySubdivision, freeformAddress: r.address.freeformAddress, localName: r.address.localName, municipality: r.address.municipality, postalCode: r.address.postalCode, streetName: r.address.streetName, streetNumber: r.address.streetNumber}})});
        } else {
          console.log(res);
        }
        // updateData(data.suggestedAddress, sA);
    }).catch(err=>console.log('err', err));
}

  console.log('sA', data);

  const {
    date,
    howDidYouHear,
    dueToCovid,
    whatCausedCrisis,
    crisisOther,
    selfCertifyCurrent,
    selfCertifyCurrentPaymentPlan,
    race,
    firstName,
    lastName,
    address1,
    address2,
    city,
    state,
    zip,
    dob,
    email,
    phone,
    gender,
    whatCity,
    howManyInHousehold,
    howManyChildrenInHousehold,
    howMany18to24InHousehold,
    howMany25to54InHousehold,
    anyoneInHouseholdDisabled,
    anyoneInHouseholdVeteran,
    typeOfHousing,
    employed,
    employment,
    howManySeniors,
    typeOfAssistance,
    assistanceAmount,
    backRentMonths,
    toaDeposit,
    depositAmount,
    toaFirstMonthsRent,
    firstMonthRentAmount,
    toaCurrentMonthsRent,
    currentMonthRentAmount,
    toaBackRent,
    backRentAmount,
    landlordFirstName,
    landlordLastName,
    landlordPhone,
    landlordaddress1,
    landlordaddress2,
    landlordcity,
    landlordstate,
    landlordzip,
    section8Voucher,
    appliedRentalAssistance,
    error,
    llName,
    upcomingRent,
    upcomingRentAmount,
    haywardAprilRent,
    haywardAprilRentAmount,
    haywardMayRent,
    haywardMayRentAmount,
    haywardJuneRent,
    haywardJuneRentAmount,
    haywardJulyRent,
    haywardJulyRentAmount,
    haywardAugustRent,
    haywardAugustRentAmount,
    haywardCurrentMarch,
    haywardCurrentMarchPaymentPlan,
    augustRent,
    augustRentAmount,
    septemberRent,
    septemberRentAmount,
    octoberRent,
    octoberRentAmount,
    landlordEmail,
    sexualOrientation
  } = data.formData;

  const { suggestedAddress, setSuggestedAddress } = data;

  const { selectedAddress, setSelectedAddress } = data;

  const updateData = (field, value) => {
    data.setFormData({ ...data.formData, [field]: value });
  };

  const imgStyle = {
    height: 65,
    // maxWidth: "80%",
    objectFit: "cover",
    display: "block",
    margin: "0 auto",
    marginTop: 10,
    marginBottom: 10,
  };
  const cityImage = () => {
    switch (whatCity) {
      case "Emeryville":
        return Emeryville;
      case "Hayward":
        return Hayward;
      case "Oakland":
        return Oakland;
      default:
        return "";
    }
  };

  // console.log("formData", data.formData);

  const checkError = (input) => {
    console.log("input", input, input.currentTarget);
  };
  const { errorFields, setErrorFields } = data;
  
  return (
    <React.Fragment>
      <div>
        <img style={imgStyle} src={cityImage()} />
      </div>
      <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
        Applicant Information
      </Typography>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={6} style={{ display: "none" }}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error}
          >
            <InputLabel shrink htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <InputLabel htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <Select
              native
              value={whatCity}
              onChange={(evt) => updateData("whatCity", evt.target.value)}
              inputProps={{
                name: "whatCity",
                id: "what-city",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Emeryville"}>Emeryville</option>
              <option value={"Hayward"}>Hayward</option>
              <option value={"Oakland"}>Oakland</option>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!howDidYouHear || howDidYouHear == "")}
          >
            <InputLabel shrink htmlFor="how-did-you-hear-about-us">
              How did you hear about us?
            </InputLabel>
            <InputLabel htmlFor="how-did-you-hear-about-us-input">
              How did you hear about us?
            </InputLabel>
            <Select
              native
              value={howDidYouHear}
              onChange={(evt) => updateData("howDidYouHear", evt.target.value)}
              inputProps={{
                name: "howDidYouHear",
                id: "how-did-you-hear-about-us-input",
              }}
            >
              <option aria-label="None" value="" />
              <option value={211}>211</option>
              <option value={"Landlord"}>Landlord</option>
              <option value={"Tenant advocacy group"}>
                Tenant advocacy group
              </option>
              <option value={"City of Hayward website or staff member"}>
                City of Hayward website or staff member
              </option>
              <option value={"City of Emeryville website or staff member"}>
                City of Emeryville website or staff member
              </option>
              <option value={"City of Oakland website or staff member"}>
                City of Oakland website or staff member
              </option>
              <option value={"Website"}>Website</option>
              <option value={"Other"}>Other</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!dueToCovid || dueToCovid == "")}
          >
            <InputLabel shrink htmlFor="dueToCovid">
              Is your housing crisis due to COVID-19?
            </InputLabel>
            <InputLabel htmlFor="dueToCovid">
              Is your housing crisis due to COVID-19?
            </InputLabel>
            <Select
              native
              value={dueToCovid}
              onChange={(evt) => {
                updateData("dueToCovid", evt.target.value);
              }}
              inputProps={{
                name: "dueToCovid",
                id: "dueToCovid",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {dueToCovid && dueToCovid == "Yes" ? (
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              disabled={dueToCovid !== "Yes"}
            >
              <InputLabel shrink htmlFor="whatCausedCrisis">
                {whatCausedCrisis !== undefined
                  ? `If yes, what caused your housing crisis?`
                  : ``}
              </InputLabel>
              <InputLabel htmlFor="whatCausedCrisis">
                If yes, what caused your housing crisis?
              </InputLabel>
              <Select
                native
                onChange={(evt) =>
                  updateData("whatCausedCrisis", evt.target.value)
                }
                inputProps={{
                  name: "whatCausedCrisis",
                  id: "whatCausedCrisis",
                }}
              >
                <option aria-label="None" value="" />
                <option value={`Loss of job / reduction in hours`}>
                  Loss of job / reduction in hours
                </option>
                <option value={`Increased expenses due to medical costs`}>
                  Increased expenses due to medical costs
                </option>
                <option
                  value={`Loss of childcare or increased expenses due to childcare`}
                >
                  Loss of childcare or increased expenses due to childcare
                </option>
                <option value={"Other"}>Other</option>
              </Select>
            </FormControl>
          ) : (
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              disabled={dueToCovid !== "No"}
            >
              <InputLabel shrink htmlFor="whatCausedCrisis">
                {whatCausedCrisis !== undefined
                  ? `If no, what caused your housing crisis?`
                  : ``}
              </InputLabel>
              <InputLabel htmlFor="whatCausedCrisis">
                If no, what caused your housing crisis?
              </InputLabel>
              <Select
                native
                onChange={(evt) =>
                  updateData("whatCausedCrisis", evt.target.value)
                }
                inputProps={{
                  name: "whatCausedCrisis",
                  id: "whatCausedCrisis",
                }}
              >
                <option aria-label="None" value="" />
                <option value={`Loss of Job or reduction in hours`}>
                  Loss of Job or reduction in hours
                </option>
                <option value={`Loss of Wage Earner in House`}>
                  Loss of Wage Earner in House
                </option>
                <option value={`Medical Emergency`}>Medical Emergency</option>
                <option value={"Other"}>Other</option>
              </Select>
            </FormControl>
          )}
        </Grid>
        {whatCausedCrisis && whatCausedCrisis == "Other" ? (
          <Grid item xs={12} sm={12}>
            <TextField
              required
              id="crisisOther"
              name="crisisOther"
              label="Please describe in a few words"
              fullWidth
              defaultValue={crisisOther}
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            <InputLabel shrink htmlFor="selfCertifyCurrent">
              Were you current for March 2020 rent?
            </InputLabel>
            <InputLabel htmlFor="selfCertifyCurrent">
              Were you current for March 2020 rent?
            </InputLabel>
            <Select
              native
              value={selfCertifyCurrent}
              onChange={(evt) =>
                updateData("selfCertifyCurrent", evt.target.value)
              }
              inputProps={{
                name: "selfCertifyCurrent",
                id: "selfCertifyCurrent",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"yes"} p={3}>
                Yes
              </option>
              <option value={"no"} p={0}>
                No
              </option>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            disabled={selfCertifyCurrent != "no"}
          >
            <InputLabel shrink htmlFor="selfCertifyCurrentPaymentPlan">
              If No, are you on a payment plan?
            </InputLabel>
            <InputLabel htmlFor="selfCertifyCurrentPaymentPlan">
              If No, are you on a payment plan?
            </InputLabel>
            <Select
              native
              value={selfCertifyCurrentPaymentPlan}
              onChange={(evt) =>
                updateData("selfCertifyCurrentPaymentPlan", evt.target.value)
              }
              inputProps={{
                name: "selfCertifyCurrentPaymentPlan",
                id: "selfCertifyCurrentPaymentPlan",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"yes"} p={3}>
                Yes
              </option>
              <option value={"no"} p={0}>
                No
              </option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            required
            error={
              error &&
              (!appliedRentalAssistance || appliedRentalAssistance == "")
            }
          >
            <InputLabel shrink htmlFor="appliedRentalAssistance">
              Have you applied for and/or received rental assistance since March
              of 2020 through Alameda County or any other resources?
            </InputLabel>
            <InputLabel htmlFor="appliedRentalAssistance">
              Have you applied for and/or received rental assistance since March
              of 2020 through Alameda County or any other resources?
            </InputLabel>
            <Select
              native
              value={appliedRentalAssistance}
              onChange={(evt) =>
                updateData("appliedRentalAssistance", evt.target.value)
              }
              inputProps={{
                name: "appliedRentalAssistance",
                id: "appliedRentalAssistance",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"yes"} p={1}>
                Yes
              </option>
              <option value={"no"} p={0}>
                No
              </option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!firstName || firstName == "")}
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="fname"
            defaultValue={firstName}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!lastName || lastName == "")}
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="lname"
            defaultValue={lastName}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="dob"
              label="Date of Birth"
              fullWidth
              name={"dob"}
              value={dob}
              // onChange={handleDateChange}
              // defaultValue={dob}
              onChange={(date, value) => updateData("dob", value)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            <InputLabel shrink htmlFor="race">
              Race
            </InputLabel>
            <InputLabel htmlFor="race">Race</InputLabel>
            <Select
              native
              value={race}
              // onChange={handleChange}
              name={"race"}
              onChange={(e) => updateData(e.target.name, e.target.value)}
              inputProps={{
                name: "race",
                id: "race",
              }}
            >
              <option aria-label="None" value="" />
              {raceChoices.map((r) => (
                <option value={r} key={`control-${r}`}>
                  {r}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            error={error && (!phone || phone == "")}
          >
            <InputLabel htmlFor="primary-phone">Primary Phone #</InputLabel>
            <Input
              id="primary-phone"
              aria-describedby="phone-helper-text"
              name={"phone"}
              value={phone ? phone : ""}
              onChange={(e) =>
                updateData(
                  e.target.name,
                  formatUSNumber(
                    e.target.value ? e.target.value.toString() : e.target.value
                  )
                )
              }
            />
            <FormHelperText id="phone-helper-text">
              {/* Your email address. */}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={(error && (!email || email == "")) || errorFields.email}
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="email"
            defaultValue={email}
            onChange={(e) => {
              var isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              console.log("is valid email", isValidEmail.test(e.target.value));
              updateData(e.target.name, e.target.value);
            }}
            onBlur={(e) => {
              var isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!isValidEmail.test(e.target.value)) {
                setErrorFields({ ...errorFields, [e.target.name]: true });
              } else {
                setErrorFields({ ...errorFields, [e.target.name]: false });
              }
              console.log("errorFields", errorFields);
            }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={6}>
          <FormControl required fullWidth className={"MuiFormControl-marginNormal"} required error={error && (!sexualOrientation || sexualOrientation == "")}>
            <InputLabel shrink htmlFor="sexualOrientation">
              Sexual Orientation
            </InputLabel>
            <InputLabel htmlFor="sexualOrientation">Sexual Orientation</InputLabel>
            <Select
              native
              value={sexualOrientation}
              // onChange={handleChange}
              name={"sexualOrientation"}
              onChange={(e) => updateData(e.target.name, e.target.value)}
              inputProps={{
                name: "sexualOrientation",
                id: "sexualOrientation",
              }}
            >
              <option aria-label="None" value="" />
              {sexualOrientationChoices.map((r) => (
                <option value={r} key={`control-${r}`}>
                  {r}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} sm={6}>
          <FormControl required fullWidth className={"MuiFormControl-marginNormal"} required error={error && (!gender || gender == "")}>
            <InputLabel shrink htmlFor="gender">
              Gender Identity
            </InputLabel>
            <InputLabel htmlFor="gender">Gender Identity</InputLabel>
            <Select
              native
              value={gender}
              // onChange={handleChange}
              name={"gender"}
              onChange={(e) => updateData(e.target.name, e.target.value)}
              inputProps={{
                name: "gender",
                id: "gender",
              }}
            >
              <option aria-label="None" value="" />
              {genderIdentityChoices.map((r) => (
                <option value={r} key={`control-${r}`}>
                  {r}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>


        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            Current Address
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Autocomplete
          onError={(e)=>{
            console.log('autocomplete did error', e)
          }}
        // options={suggestedAddress && suggestedAddress.address ? selectedAddress ? suggestedAddress.address.map(f=>{return {value: `${f.address.streetNumber} ${f.address.streetName}`, inputValue: `${f.address.streetNumber} ${f.address.streetName}`}}).concat([{value: selectedAddress, inputValue: selectedAddress}]) : suggestedAddress.address.map(f=>{return {value: `${f.address.streetNumber} ${f.address.streetName}`, inputValue: `${f.address.streetNumber} ${f.address.streetName}` }}) : []}        
          options={suggestedAddress.address}
          getOptionLabel={(option) => {console.log('option', option); return option && option.value && option.inputValue ? `${option.freeformAddress}` : `${option.toString()}`}}
          // defaultValue={address1}
          // renderOption={(option,state)=>{
          //   console.log('render option', option,state);
          // }}
          // value={s}
          
          value={address1 ? address1 : ''}
          // defaultValue={address1}
          // getOptionSelected={(option,value)=>selectedAddress ? true : false}
          onInputChange={(e, newValue) => {  
            console.log('autocomplete on change', newValue, e, e ? e.target : 'no target')
            setSelectedAddress(newValue);
            if (e && e.target && e.target.value && e.target.value.length > 4 && e.target.value.indexOf(' ') >= 0){
              findAddress(e.target.value);
            } else {
              setSuggestedAddress({address: []})
            }
            if (e && e.target){
            updateData(e.target.name, e.target.value)
            // updateData('city', newValue.address)
            }
            
          }}
          clearOnBlur={false}
          onChange={(event, option)=>{
            console.log('on auto did change', option);
            if (option && option.streetNumber)
              data.setFormData({ ...data.formData, address1: `${option.streetNumber} ${option.streetName}`, city: option.localName, zip: option.postalCode });
            }  
          }
          renderOption={(option)=>{
            console.log('render option', option);
            if (address1 !== `${option.streetNumber} ${option.streeName}`){
              // updateData('address1', `${option.address.streetNumber} ${option.address.streetName}`)
              // data.setFormData({ ...data.formData, address1: selectedAddress, city: option.address.city, zip: option.address.postalCode });
            }
            // if (city !== option.address.localName) {
            //   updateData('city', option.address.localName)
            // }
            return <div>{`${option.freeformAddress}`}</div>
            }
          }
          renderInput={(params) => {
            console.log('input params', params, suggestedAddress,selectedAddress);
            // if (selectedAddress && selectedAddress.indexOf(', ')) params.inputProps.value == selectedAddress.substring(0, selectedAddress.indexOf(', ')) && 
             return (
              //  ((selectedAddress && selectedAddress.indexOf(', ')) params.inputProps.value == selectedAddress.substring(0, selectedAddress.indexOf(', '))) &&
            <TextField
              {...params}
              id="address1"
              name="address1"
              label="Address line 1"
              fullWidth
              value={address1}
              // autoComplete="address-line1"              
              // defaultValue={address1}
              // value={address1}
              // onChange={(e) => {
              //   console.log('address line 1', e.target.value);
              //   if (e.target.value && e.target.value.length > 4 && e.target.value.indexOf(' ') >= 0){
              //     findAddress(e.target.value);
              //   }
              //   updateData(e.target.name, e.target.value)
              // }
              // }
          />)
          }}
        />

        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="address-line2"
            defaultValue={address2}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!city || city == "")}
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="address-level2"
            value={city ? city : ''}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!zip || zip == "")}
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
            // defaultValue={zip}
            value={zip ? zip : ''}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Divider flexItem />
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            Household Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={error && (!howManyInHousehold || howManyInHousehold == "")}
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="how-many-in-household">
              How many people are in your household? (include yourself)
            </InputLabel>
            <InputLabel htmlFor="howManyInHousehold">
              How many people are in your household? (include yourself)
            </InputLabel>
            <Select
              native
              value={howManyInHousehold}
              onChange={(evt) =>
                updateData("howManyInHousehold", evt.target.value)
              }
              inputProps={{
                name: "howManyInHousehold",
                id: "how-many-in-household",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
              <option value={"4"}>4</option>
              <option value={"5+"}>5+</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={
              error &&
              (!howManyChildrenInHousehold || howManyChildrenInHousehold == "")
            }
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="how-many-children-household">
              How many children under the age of 18 yrs.?
            </InputLabel>
            <InputLabel htmlFor="howManyChildrenInHousehold">
              How many children under the age of 18 yrs.?
            </InputLabel>
            <Select
              native
              value={howManyChildrenInHousehold}
              onChange={(evt) =>
                updateData("howManyChildrenInHousehold", evt.target.value)
              }
              inputProps={{
                name: "howManyChildrenInHousehold",
                id: "how-many-children-household",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"0"}>0</option>
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
              <option value={"4"}>4</option>
              <option value={"5+"}>5+</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={
              error &&
              (!howMany18to24InHousehold || howMany18to24InHousehold == "")
            }
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="how-many-18to24-household">
              How many people are between the ages of 18-24 yrs.?
            </InputLabel>
            <InputLabel htmlFor="howMany18to24InHousehold">
              How many people are between the ages of 18-24 yrs.?
            </InputLabel>
            <Select
              native
              value={howMany18to24InHousehold}
              onChange={(evt) =>
                updateData("howMany18to24InHousehold", evt.target.value)
              }
              inputProps={{
                name: "howMany18to24InHousehold",
                id: "how-many-18to24-household",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"0"}>0</option>
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
              <option value={"4"}>4</option>
              <option value={"5+"}>5+</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={
              error &&
              (!howMany25to54InHousehold || howMany25to54InHousehold == "")
            }
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="how-many-25to54-household">
              How many people are between the ages of 25-54 yrs.?
            </InputLabel>
            <InputLabel htmlFor="howMany25to54InHousehold">
              How many people are between the ages of 25-54 yrs.?
            </InputLabel>
            <Select
              native
              value={howMany25to54InHousehold}
              onChange={(evt) =>
                updateData("howMany25to54InHousehold", evt.target.value)
              }
              inputProps={{
                name: "howMany25to54InHousehold",
                id: "how-many-25to54-household",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"0"}>0</option>
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
              <option value={"4"}>4</option>
              <option value={"5+"}>5+</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={error && (!howManySeniors || howManySeniors == "")}
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="how-many-seniors">
              How many seniors are 62 yrs. and older?
            </InputLabel>
            <InputLabel htmlFor="howManySeniors">
              How many seniors are 62 yrs. and older?
            </InputLabel>
            <Select
              native
              value={howManySeniors}
              onChange={(evt) => updateData("howManySeniors", evt.target.value)}
              inputProps={{
                name: "howManySeniors",
                id: "how-many-seniors",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"0"}>0</option>
              <option value={"0"}>0</option>
              <option value={"1"}>1</option>
              <option value={"2"}>2</option>
              <option value={"3"}>3</option>
              <option value={"4"}>4</option>
              <option value={"5+"}>5+</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={
              error &&
              (!anyoneInHouseholdDisabled || anyoneInHouseholdDisabled == "")
            }
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="household-disabled">
              Are you or anyone in the household Disabled?
            </InputLabel>
            <InputLabel htmlFor="anyoneInHouseholdDisabled">
              Are you or anyone in the household Disabled?
            </InputLabel>
            <Select
              native
              value={anyoneInHouseholdDisabled}
              onChange={(evt) =>
                updateData("anyoneInHouseholdDisabled", evt.target.value)
              }
              inputProps={{
                name: "anyoneInHouseholdDisabled",
                id: "household-disabled",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={
              error &&
              (!anyoneInHouseholdVeteran || anyoneInHouseholdVeteran == "")
            }
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="household-veteran">
              Are you or any other adult in the household a veteran?
            </InputLabel>
            <InputLabel htmlFor="anyoneInHouseholdVeteran">
              Are you or any other adult in the household a veteran?
            </InputLabel>
            <Select
              native
              value={anyoneInHouseholdVeteran}
              onChange={(evt) =>
                updateData("anyoneInHouseholdVeteran", evt.target.value)
              }
              inputProps={{
                name: "anyoneInHouseholdVeteran",
                id: "household-veteran",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            required
            error={error && (!typeOfHousing || typeOfHousing == "")}
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="type-of-housing">
              Type of Housing?
            </InputLabel>
            <InputLabel htmlFor="typeOfHousing">Type of Housing?</InputLabel>
            <Select
              native
              value={typeOfHousing}
              onChange={(evt) => updateData("typeOfHousing", evt.target.value)}
              inputProps={{
                name: "typeOfHousing",
                id: "typeOfHousing",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Home Owner"}>Home Owner</option>
              <option value={"Rent Apartment or Room"}>
                Rent Apartment or Room
              </option>
              <option value={"Homeless"}>Homeless</option>
              <option value={"Shelter"}>Shelter</option>
              <option value={"With Friend or Family"}>
                With Friends or Family
              </option>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="section8Voucher">
              Do you currently have a Section 8 Voucher?
            </InputLabel>
            <InputLabel htmlFor="section8Voucher">
              Do you currently have a Section 8 Voucher?
            </InputLabel>
            <Select
              native
              value={section8Voucher}
              onChange={(evt) =>
                updateData("section8Voucher", evt.target.value)
              }
              inputProps={{
                name: "section8Voucher",
                id: "section8Voucher",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            Employment
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            <InputLabel shrink htmlFor="employed">
              Employed?
            </InputLabel>
            <InputLabel htmlFor="employed">Employed?</InputLabel>
            <Select
              native
              value={employed}
              onChange={(evt) => updateData("employed", evt.target.value)}
              inputProps={{
                name: "employed",
                id: "employed",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"yes"}>Yes</option>
              <option value={"no"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            <InputLabel shrink htmlFor="employment">
              Employment
            </InputLabel>
            <InputLabel htmlFor="employment">Employment</InputLabel>
            <Select
              native
              value={employment}
              onChange={(evt) => updateData("employment", evt.target.value)}
              inputProps={{
                name: "employment",
                id: "employment",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Full-time"}>Full-time</option>
              <option value={"Part-time"}>Part-time</option>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
            <InputLabel shrink htmlFor="type-of-assistance">
              What type of assistance are you seeking today? (MUST HAVE
              DOCUMENTATION)
            </InputLabel>
            <InputLabel htmlFor="typeOfAssistance">
              What type of assistance are you seeking today? (MUST HAVE
              DOCUMENTATION)
            </InputLabel>
            <Select
              native
              value={typeOfAssistance}
              onChange={(evt) =>
                updateData("typeOfAssistance", evt.target.value)
              }
              inputProps={{
                name: "typeOfAssistance",
                id: "type-of-assistance",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Full-time"}>De-time</option>
              <option value={"Part-time"}>Part-time</option>
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            What type of assistance are you seeking today? (Must have proof of
            rent owed, etc.)
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            {/* <FormLabel component="legend">1st Month's Rent</FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={race && race.indexOf(r) >= 0 ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("toaBackRent", checked);
                    }}
                    checked={toaBackRent ? true : false}
                    name={"BackRent"}
                  />
                }
                label={"Back Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
          sm={1}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!toaBackRent}
            id="backRentAmount"
            name="backRentAmount"
            label="Amount"
            fullWidth
            value={backRentAmount ? backRentAmount : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={4}
          sm={2}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!toaBackRent}
            id="backRentMonths"
            name="backRentMonths"
            label="# of Months?"
            fullWidth
            value={backRentMonths ? backRentMonths : ""}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Emeryville" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            {/* <FormLabel component="legend">Deposit</FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Emeryville" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("upcomingRent", checked);
                    }}
                    checked={upcomingRent ? true : false}
                    name={"upcomingRent"}
                  />
                }
                label={"August 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Emeryville" ? "none" : "",
          }}
        >
          <TextField
            disabled={!upcomingRent}
            id="upcomingRentAmount"
            name="upcomingRentAmount"
            label="Amount"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            fullWidth
            value={upcomingRentAmount}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            {/* <FormLabel component="legend">Deposit</FormLabel> */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("haywardAprilRent", checked);
                    }}
                    checked={haywardAprilRent ? true : false}
                    name={"haywardAprilRent"}
                  />
                }
                label={"April 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!haywardAprilRent}
            id="haywardAprilRentAmount"
            name="haywardAprilRentAmount"
            label="April 2020 Rent Amount"
            fullWidth
            value={haywardAprilRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("haywardMayRent", checked);
                    }}
                    checked={haywardMayRent ? true : false}
                    name={"haywardMayRent"}
                  />
                }
                label={"May 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!haywardMayRent}
            id="haywardMayRentAmount"
            name="haywardMayRentAmount"
            label="May 2020 Rent Amount"
            fullWidth
            value={haywardMayRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("haywardJuneRent", checked);
                    }}
                    checked={haywardJuneRent ? true : false}
                    name={"haywardJuneRent"}
                  />
                }
                label={"June 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!haywardJuneRent}
            id="haywardJuneRentAmount"
            name="haywardJuneRentAmount"
            label="June 2020 Rent Amount"
            fullWidth
            value={haywardJuneRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("haywardJulyRent", checked);
                    }}
                    checked={haywardJulyRent ? true : false}
                    name={"haywardJulyRent"}
                  />
                }
                label={"July 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity != "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!haywardJulyRent}
            id="haywardJulyRentAmount"
            name="haywardJulyRentAmount"
            label="July 2020 Rent Amount"
            fullWidth
            value={haywardJulyRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            visibility: whatCity != "Hayward" ? "hidden" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("augustRent", checked);
                    }}
                    checked={augustRent ? true : false}
                    name={"augustRent"}
                  />
                }
                label={"August 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            visibility: whatCity != "Hayward" ? "hidden" : "",
          }}
        >
          <TextField
            disabled={!augustRent}
            id="augustRentAmount"
            name="augustRentAmount"
            label="August 2020 Rent Amount"
            fullWidth
            value={augustRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity != "Hayward" && whatCity != "Emeryville" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("septemberRent", checked);
                    }}
                    checked={septemberRent ? true : false}
                    name={"septemberRent"}
                  />
                }
                label={"September 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity != "Hayward" && whatCity != "Emeryville" ? "none" : "",
          }}
        >
          <TextField
            disabled={!septemberRent}
            id="septemberRentAmount"
            name="septemberRentAmount"
            label="September 2020 Rent Amount"
            fullWidth
            value={septemberRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity == "Emeryville" ? "" : "none",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={whatCity == "Hayward" ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("octoberRent", checked);
                    }}
                    checked={octoberRent ? true : false}
                    name={"octoberRent"}
                  />
                }
                label={"October 2020 Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display: whatCity == "Emeryville" ? "" : "none",
          }}
        >
          <TextField
            disabled={!octoberRent}
            id="octoberRentAmount"
            name="octoberRentAmount"
            label="October 2020 Rent Amount"
            fullWidth
            value={octoberRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        {/* <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={race && race.indexOf(r) >= 0 ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("toaDeposit", checked);
                    }}
                    checked={toaDeposit ? true : false}
                    name={"Deposit"}
                  />
                }
                label={"Deposit"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!toaDeposit}
            id="depositAmount"
            name="depositAmount"
            label="Amount"
            fullWidth
            value={depositAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid> */}
        {/* <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={race && race.indexOf(r) >= 0 ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("toaFirstMonthsRent", checked);
                    }}
                    checked={toaFirstMonthsRent ? true : false}
                    name={"FirstMonthsRent"}
                  />
                }
                label={"First Month's Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!toaFirstMonthsRent}
            id="firstMonthRentAmount"
            name="firstMonthRentAmount"
            label="Amount"
            fullWidth
            value={firstMonthRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid> */}
        {/* <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <FormControl component="fieldset" fullWidth>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    // defaultChecked={race && race.indexOf(r) >= 0 ? true : false}
                    inputProps={{ role: "checkbox" }}
                    onChange={(a, checked) => {
                      updateData("toaCurrentMonthsRent", checked);
                    }}
                    checked={toaCurrentMonthsRent ? true : false}
                    name={"CurrentMonthsRent"}
                  />
                }
                label={"Current Month's Rent"}
              />
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={3}
          style={{
            display:
              whatCity == "Emeryville" || whatCity == "Hayward" ? "none" : "",
          }}
        >
          <TextField
            disabled={!toaCurrentMonthsRent}
            id="currentMonthRentAmount"
            name="currentMonthRentAmount"
            label="Amount"
            fullWidth
            value={currentMonthRentAmount}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid> */}

        {whatCity == "Hayward" && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: whatCity != "Hayward" ? "none" : "" }}
          >
            <FormControl
              fullWidth
              className={"MuiFormControl-marginNormal"}
              required
              error={
                error && (!haywardCurrentMarch || haywardCurrentMarch == "")
              }
            >
              <InputLabel shrink htmlFor="haywardCurrentMarch">
                Was your rent current for March 2020?
              </InputLabel>
              <InputLabel htmlFor="haywardCurrentMarch">
                Was your rent current for March 2020?
              </InputLabel>
              <Select
                native
                value={haywardCurrentMarch}
                onChange={(evt) =>
                  updateData("haywardCurrentMarch", evt.target.value)
                }
                inputProps={{
                  name: "haywardCurrentMarch",
                  id: "haywardCurrentMarch",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </Select>
            </FormControl>
          </Grid>
        )}

        {whatCity == "Hayward" && haywardCurrentMarch == "no" && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: whatCity != "Hayward" ? "none" : "" }}
          >
            <FormControl
              error={
                error &&
                (!haywardCurrentMarchPaymentPlan ||
                  haywardCurrentMarchPaymentPlan == "")
              }
              fullWidth
              required
              className={"MuiFormControl-marginNormal"}
              disabled={haywardCurrentMarch != "no"}
            >
              <InputLabel shrink htmlFor="haywardCurrentMarchPaymentPlan">
                If no, are you on a payment plan?
              </InputLabel>
              <InputLabel htmlFor="haywardCurrentMarchPaymentPlan">
                If no, are you on a payment plan?
              </InputLabel>
              <Select
                native
                value={haywardCurrentMarchPaymentPlan}
                onChange={(evt) =>
                  updateData("haywardCurrentMarchPaymentPlan", evt.target.value)
                }
                inputProps={{
                  name: "haywardCurrentMarchPaymentPlan",
                  id: "haywardCurrentMarchPaymentPlan",
                }}
              >
                <option aria-label="None" value="" />
                <option value={"yes"}>Yes</option>
                <option value={"no"}>No</option>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            Landlord Information
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            style={{ color: "#666", fontSize: "1rem" }}
          >
            Please list name and address where you make your rent checks payable
            to.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={error && (!llName || llName == "")}
            id="llName"
            name="llName"
            value={llName ? llName : ""}
            label="Landlord (First & Last) or Company Name"
            fullWidth
            autoComplete="llName"
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            error={error && (!landlordaddress1 || landlordaddress1 == "")}
            id="landlordaddress1"
            name="landlordaddress1"
            value={landlordaddress1 ? landlordaddress1 : ""}
            label="Landlord Address line 1"
            fullWidth
            autoComplete="address-line1"
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="landlordaddress2"
            name="landlordaddress2"
            value={landlordaddress2 ? landlordaddress2 : ""}
            label="Landlord Address line 2"
            fullWidth
            autoComplete="address-line2"
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!landlordcity || landlordcity == "")}
            id="landlordcity"
            name="landlordcity"
            value={landlordcity ? landlordcity : ""}
            label="Landlord City"
            fullWidth
            autoComplete="address-level2"
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="landlordstate"
            name="landlordstate"
            required
            error={error && (!landlordstate || landlordstate == "")}
            value={landlordstate ? landlordstate : ""}
            label="Landlord State/Province/Region"
            onChange={(e) => updateData(e.target.name, e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!landlordzip || landlordzip == "")}
            id="landlordzip"
            name="landlordzip"
            value={landlordzip ? landlordzip : ""}
            label="Landlord Zip / Postal code"
            fullWidth
            autoComplete="billing postal-code"
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            fullWidth
            required
            error={error && (!landlordPhone || landlordPhone == "")}
          >
            <InputLabel htmlFor="landlord-primary-phone">
              Landlord Primary Phone #
            </InputLabel>
            <Input
              id="landlord-primary-phone"
              aria-describedby="landlord-phone-helper-text"
              value={landlordPhone ? landlordPhone : ""}
              name={"landlordPhone"}
              onChange={(e) =>
                updateData(
                  e.target.name,
                  formatUSNumber(
                    e.target.value ? e.target.value.toString() : e.target.value
                  )
                )
              }
            />
            <FormHelperText id="landlord-phone-helper-text">
              {/* Your email address. */}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={error && (!landlordEmail || landlordEmail == "")}
            id="landlordEmail"
            name="landlordEmail"
            value={landlordEmail ? landlordEmail : ""}
            label="Landlord EMail"
            fullWidth
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
