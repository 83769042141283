import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Oakland from "./images/oakland.jpg";
import Emeryville from "./images/emeryville.png";
import Hayward from "./images/hayward.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";

const cityImage = (city) => {
  switch (city) {
    case "Emeryville":
      return Emeryville;
    case "Hayward":
      return Hayward;
    case "Oakland":
      return Oakland;
    default:
      return null;
  }
};

export default function SubmissionForm(formData) {
  let esig = React.useRef();

  const {
    agreed,
    signedName,
    whatCity,
    submitError,
    errorMessage,
    submittedBy,
  } = formData.formData;
  const { isSubmitting, setSubmitting } = formData;
  // console.log("isSubmitting", isSubmitting, setSubmitting, formData);
  return (
    <React.Fragment>
      {isSubmitting && (
        <div>
          {" "}
          <CircularProgress /> <div>Submitting Form - Please Wait</div>
        </div>
      )}
      {!isSubmitting && (
        <div>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img src={cityImage(whatCity)} style={{ maxHeight: 75 }} />
            </Grid>
          </Grid>
          <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
            Consent, Release of Information
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                I authorize Bay Area Community Services to release the
                information contained in this application to any funder of this
                program in order to verify my eligibility for the program. This
                may also include any other agency or property management
                company/property owner who could be helpful in understanding my
                situation. Upon approval of my application I further authorize
                the release and sharing of information between BACS, the funding
                agency, the landlord, and any third party research firm for up
                to three years after receiving financial assistance from the
                program. I understand information shared will be necessary and
                appropriate for administering the financial assistance program
                provided and for coordinating and verifying services on my
                behalf. I understand that information that I share will remain
                confidential, and will only be used for the purposes described
                above. I hereby affirm that the information provided my
                application is true and complete to the best of my knowledge. I
                understand that if I provide any false information or
                misrepresentation during the application process that it will be
                grounds for denying my application to the Program. I understand
                in submitting this application I am not guaranteed financial
                assistance from the Program. In addition, my signature below
                acknowledges my understanding and consent to the release of
                information as described above. I understand that I have the
                right to revoke this consent in writing at any time, except for
                information already released, and that unless I decide to revoke
                it sooner, this consent will terminate three years after receipt
                of assistance from the program. If approved, I agree to provide
                tenancy information to Agency representative through means such
                as text messages, phone, or email for the purposes of evaluation
                purposes. I understand that in order for BACS to continuously
                improve our services and understand the effectiveness of their
                support, they would like to follow up in{" "}
                {whatCity == "Oakland" ? 60 : 90}
                {whatCity == "Oakland"
                  ? " days, 6 months, and 1-year after services have been provided."
                  : " days."}
              </Typography>
              <br />
              {whatCity == "Oakland" ? (
                <Typography>
                  Your signature below states that you recognize that electronic
                  communications (text message) are not secure; you hereby
                  authorize BACS to communicate with me regarding my services
                  received from the Keep Everyone Housed Program using
                  electronic communications as indicated below and have provided
                  the phone number above to text message: And you understand
                  that text messaging is to be used to the purposes of
                  communicating on a limited basis and are not intended to
                  provide additional support. If you are in need of additional
                  support when you receive a text message, you understand that
                  you should not text your concerns; rather, you should contact
                  BACS via phone or in-person. You understand that you can end
                  (“revoke”) this waiver at any time during service.
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={4} implementation="css" />
            <Grid item xs={4}>
              <FormControl fullWidth className={"MuiFormControl-marginNormal"}>
                <InputLabel shrink htmlFor="submittedBy">
                  Form Submitted By
                </InputLabel>
                <InputLabel htmlFor="submittedBy">Form Submitted By</InputLabel>
                <Select
                  native
                  value={submittedBy}
                  // onChange={handleChange}
                  name={"submittedBy"}
                  onChange={(e) =>
                    formData.setFormData({
                      ...formData.formData,
                      submittedBy: e.target.value,
                    })
                  }
                  inputProps={{
                    name: "submittedBy",
                    id: "submittedBy",
                  }}
                >
                  <option aria-label="None" value="Myself">
                    MYSELF
                  </option>
                  <option aria-label="None" value="89301">
                    89301
                  </option>
                  <option aria-label="None" value="89302">
                    89302
                  </option>
                  <option aria-label="None" value="89303">
                    89303
                  </option>
                  <option aria-label="None" value="89304">
                    89304
                  </option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} implementation="css" />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    name="agreed"
                    control={agreed.toString()}
                    value={agreed ? "yes" : false}
                    checked={agreed}
                    onChange={(evt, agreed) => {
                      formData.setFormData({ ...formData.formData, agreed });
                      if (agreed)
                        setTimeout(() => {
                          esig && esig.current.focus();
                        }, 250);
                    }}
                  />
                }
                label="I certify that the information submitted in this application is true and correct to the best of my knowledge."
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                control
                required
                control={
                  formData.formData.signedName
                    ? formData.formData.signedName.toString()
                    : ""
                }
                disabled={!agreed}
                onChange={(evt) =>
                  formData.setFormData({
                    ...formData.formData,
                    signedName: evt.target.value,
                  })
                }
                value={signedName && agreed ? signedName : ""}
                inputRef={esig}
                focused={agreed}
                id="electronic-signature"
                name="electronicSignature"
                label="Type your full name for electronic signature submission"
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}
