import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Slider from "@material-ui/core/Slider";

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export default function Questionarre(formData, setFormData) {
  const updateData = (field, value) => {
    const data = formData;
    data.setFormData({ ...data.formData, [field]: value });
  };

  const {
    crisisOther,

    whatCausedCrisis,
    score,
    haveEvicNotice,
    evicNoticeHowLongStay,
    householdMonthlyGross,
    amiCalculator,
    householdMonthlyExp,
    expSuddenLossIncome,
    movedEconomicFactors,
    spentOneNightHomeless,
    expHomelessness,
    liveInSubsidized,
    makeLess30AMI,
    householdPregnant,
    familyCPS,
    error,
    appliedRentalAssistance,
    whatCity,
    selfCertifyCurrent,
    selfCertifyCurrentPaymentPlan,
    evictionMoratorium,
    evictionMoratoriumLength,
    policeInvolvement,
    livingInCensusTracks,
  } = formData.formData;
  // console.log("questionare data", formData);

  const limits2019 = [27450, 31350, 35250, 39150, 42300, 45450, 48550, 51700];
  const emyLimits2019 = [78200, 83950, 100550, 111700, 120650];

  const limits2020 = [];

  if (
    whatCity &&
    whatCity == "Emeryville" &&
    amiCalculator > emyLimits2019.length
  )
    setTimeout(() => {
      updateData("amiCalculator", 1);
    }, 200);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
        These questions are used to better understand your current housing needs
      </Typography>

      <Grid container spacing={3}>
        {/*  add initial box with  I hereby certify and attest that my rent was current before March 1 }  */}
        {/* <Grid
          item
          xs={12}
          style={{ display: whatCity != "Emeryville" ? "none" : "" }}
        >
          <TextField
            error={error}
            id="selfCertifyCurrent"
            name="selfCertifyCurrent"
            label="Initial here to certify and attest that March 2020 rent is current"
            fullWidth
            defaultValue={selfCertifyCurrent}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid> */}
        <Grid item xs={12}>
          <FormControl
            fullWidth
            required
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="expHomelessness">
              Have you previously experienced homelessness (ever, in your life)?
            </InputLabel>
            <InputLabel htmlFor="expHomelessness">
              Have you previously experienced homelessness (ever, in your life)?
            </InputLabel>
            <Select
              required
              error={error && (!expHomelessness || expHomelessness == "")}
              native
              value={expHomelessness}
              onChange={(evt) =>
                updateData("expHomelessness", evt.target.value)
              }
              inputProps={{
                name: "expHomelessness",
                id: "expHomelessness",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="liveInSubsidized">
              Do you live in subsidized housing or Public Housing Authority
              housing AND have a cash assistance need?
            </InputLabel>
            <InputLabel htmlFor="liveInSubsidized">
              Do you live in subsidized housing or Public Housing Authority
              housing AND have a cash assistance need?
            </InputLabel>
            <Select
              required
              error={error && (!liveInSubsidized || liveInSubsidized == "")}
              native
              value={liveInSubsidized}
              onChange={(evt) =>
                updateData("liveInSubsidized", evt.target.value)
              }
              inputProps={{
                name: "liveInSubsidized",
                id: "liveInSubsidized",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="spentOneNightHomeless">
              Have you spent at least one night during the prior 60 days
              literally homeless (staying in a place not meant for human
              habitation or emergency shelter)?
            </InputLabel>
            <InputLabel htmlFor="spentOneNightHomeless">
              Have you spent at least one night during the prior 60 days
              literally homeless (staying in a place not meant for human
              habitation or emergency shelter)?
            </InputLabel>
            <Select
              required
              error={
                error && (!spentOneNightHomeless || spentOneNightHomeless == "")
              }
              native
              value={spentOneNightHomeless}
              onChange={(evt) =>
                updateData("spentOneNightHomeless", evt.target.value)
              }
              inputProps={{
                name: "spentOneNightHomeless",
                id: "spentOneNightHomeless",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            required
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="movedEconomicFactors">
              Have you moved because of economic factors two or more times in
              the past 60 days? That may include things like a job loss or
              inability to afford rising rents.
            </InputLabel>
            <InputLabel htmlFor="movedEconomicFactors">
              Have you moved because of economic factors two or more times in
              the past 60 days? That may include things like a job loss or
              inability to afford rising rents.
            </InputLabel>
            <Select
              required
              error={
                error && (!movedEconomicFactors || movedEconomicFactors == "")
              }
              native
              value={movedEconomicFactors}
              onChange={(evt) =>
                updateData("movedEconomicFactors", evt.target.value)
              }
              inputProps={{
                name: "movedEconomicFactors",
                id: "movedEconomicFactors",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
          >
            <InputLabel shrink htmlFor="evictionMoratorium">
              Even with the Eviction Moratorium in place, is your landlord still
              threatening or asking you to leave?
            </InputLabel>
            <InputLabel htmlFor="evictionMoratorium">
              Even with the Eviction Moratorium in place, is your landlord still
              threatening or asking you to leave?
            </InputLabel>
            <Select
              native
              required
              error={
                error && (!movedEconomicFactors || movedEconomicFactors == "")
              }
              value={evictionMoratorium}
              onChange={(evt) =>
                updateData("evictionMoratorium", evt.target.value)
              }
              inputProps={{
                name: "evictionMoratorium",
                id: "evictionMoratorium",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            style={{ marginTop: 16 }}
            disabled={!evictionMoratorium || evictionMoratorium != "Yes"}
            id="evictionMoratoriumLength"
            name="evictionMoratoriumLength"
            label="If yes, how long are they claiming you can stay there?"
            fullWidth
            value={evictionMoratoriumLength ? evictionMoratoriumLength : ""}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            <InputLabel shrink htmlFor="haveEvicNotice">
              Do you currently have an Eviction Notice or 3-Day Notice to vacate
              your housing?
            </InputLabel>
            <InputLabel htmlFor="haveEvicNotice">
              Do you currently have an Eviction Notice or 3-Day Notice to vacate
              your housing?
            </InputLabel>
            <Select
              native
              value={haveEvicNotice}
              onChange={(evt) => updateData("haveEvicNotice", evt.target.value)}
              inputProps={{
                name: "haveEvicNotice",
                id: "haveEvicNotice",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        {haveEvicNotice && haveEvicNotice >= 3 ? (
          <Grid item xs={12}>
            <TextField
              required
              id="evicNoticeHowLongStay"
              name="evicNoticeHowLongStay"
              label="If yes, how long are you able to stay there?"
              fullWidth
              defaultValue={evicNoticeHowLongStay}
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </Grid>
        ) : null} */}
        {whatCity != "Hayward" && (
          <Grid item xs={12} style={{ paddingLeft: "5%", paddingRight: "5%" }}>
            <Typography variant="h6" gutterBottom>
              {whatCity == "Emeryville"
                ? "Emeryville 2019 100% AMI Income Limit Calculator"
                : "2020 30% of AMI Calculator"}
            </Typography>
            <Slider
              style={{ paddingLeft: 5, paddingRight: 5 }}
              defaultValue={1}
              aria-labelledby="discrete-slider"
              valueLabelDisplay="auto"
              step={1}
              marks
              min={1}
              max={
                whatCity == "Emeryville"
                  ? emyLimits2019.length
                  : limits2019.length
              }
              value={amiCalculator}
              onChange={(evt, val) => updateData("amiCalculator", val)}
              marks={
                whatCity == "Emeryville"
                  ? emyLimits2019.map((limit, i) => {
                      // console.log("mapping emyLimits", limit, i);
                      return {
                        value: i + 1,
                        label: i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
                  : limits2019.map((limit, i) => {
                      // console.log("mapping normal limits");
                      return {
                        value: i + 1,
                        label: i + 1 + (i + 1 == 1 ? " person" : " people"),
                      };
                    })
              }
            />
            {/* <List>
            {limits2019.map((val, i) => (
              <ListItem key={`limit-${i}`} alignItems={`flex-start`}>
                <ListItemText
                  primary={`${i + 1} ${
                    i <= 0 ? " person" : " people"
                  }: ${formatter.format(val).toString().replace(".00", "")}`}
                />
              </ListItem>
            ))}
          </List> */}
          </Grid>
        )}
        {whatCity != "Hayward" && (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{
              marginTop: -10,
              paddingTop: 0,
              textDecoration: "bold",
            }}
          >
            <div style={{ fontWeight: 600, width: "100%" }}>
              {amiCalculator && amiCalculator > 0 ? (
                <div>
                  {amiCalculator}{" "}
                  {amiCalculator <= 1 ? " person limit: " : " people limit: "}
                  {formatter
                    .format(
                      whatCity == "Emeryville"
                        ? emyLimits2019[amiCalculator - 1]
                        : limits2019[amiCalculator - 1]
                    )
                    .toString()
                    .replace(".00", "")}
                </div>
              ) : null}
            </div>
          </Grid>
        )}
        {whatCity != "Hayward" && (
          <Grid item xs={12} sm={12}>
            <FormControl
              required
              error={error && (!makeLess30AMI || makeLess30AMI == "")}
              fullWidth
              className={"MuiFormControl-marginNormal longText"}
            >
              <InputLabel shrink htmlFor="makeLess30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>
                    Did your household make less than 30% AMI annually{" "}
                    <b>before</b>
                    COVID-19?
                  </span>
                )}
              </InputLabel>
              <InputLabel htmlFor="makeLess30AMI">
                {whatCity == "Emeryville" ? (
                  "Does your household make less than 100% AMI annually"
                ) : (
                  <span>
                    Did your household make less than 30% AMI annually{" "}
                    <b>before</b> COVID-19?
                  </span>
                )}
              </InputLabel>
              <Select
                native
                value={makeLess30AMI}
                onChange={(evt) =>
                  updateData("makeLess30AMI", evt.target.value)
                }
                inputProps={{
                  name: "makeLess30AMI",
                  id: "makeLess30AMI",
                }}
              >
                <option aria-label="None" value="" p={0} />
                <option value={"Yes"}>Yes</option>
                <option value={"No"}>No</option>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12} className={"longText"}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
          >
            {/* <InputLabel htmlFor="householdMonthlyGross">
              What’s your household’s current monthly gross income from ALL
              sources (including Wages, Business Income, Social Security,
              Disability Payments, Other Income)?
            </InputLabel> */}
            <TextField
              required
              error={
                error && (!householdMonthlyGross || householdMonthlyGross == "")
              }
              id="householdMonthlyGross"
              name="householdMonthlyGross"
              label={
                <span>
                  What’s your household’s <b>current</b> monthly gross income
                  from ALL sources (including Wages, Business Income, Social
                  Security, Disability Payments, Other Income)?
                </span>
              }
              fullWidth
              defaultValue={householdMonthlyGross}
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          </FormControl>
        </Grid>
        `{" "}
        {/* <Grid item xs={12}>
          <TextField
            required
            error={error && (!householdMonthlyExp || householdMonthlyExp == "")}
            id="householdMonthlyExp"
            name="householdMonthlyExp"
            label="What’s your household’s monthly expenses? "
            fullWidth
            defaultValue={householdMonthlyExp}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Grid>` */}
        <Grid item xs={12} sm={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            required
            error={error && (!expSuddenLossIncome || expSuddenLossIncome == "")}
          >
            <InputLabel shrink htmlFor="expSuddenLossIncome">
              Has anyone in your household experienced a sudden and significant
              loss of income, including employment and/or cash/benefits?
            </InputLabel>
            <InputLabel htmlFor="expSuddenLossIncome">
              Has anyone in your household experienced a sudden and significant
              loss of income, including employment and/or cash/benefits?
            </InputLabel>
            <Select
              native
              required
              value={expSuddenLossIncome}
              onChange={(evt) =>
                updateData("expSuddenLossIncome", evt.target.value)
              }
              inputProps={{
                name: "expSuddenLossIncome",
                id: "expSuddenLossIncome",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <Typography
            variant="h6"
            style={{
              width: "80%",
              textAlign: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            The following questions are optional, will not affect you
            eligibility, and are only be used to determine whether applicants
            fall into other priority populations.
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            error={error && (!householdPregnant || householdPregnant == "")}
          >
            <InputLabel shrink htmlFor="householdPregnant">
              Is anyone in your household currently pregnant?
            </InputLabel>
            <InputLabel htmlFor="householdPregnant">
              Is anyone in your household currently pregnant?
            </InputLabel>
            <Select
              native
              required
              value={householdPregnant}
              onChange={(evt) =>
                updateData("householdPregnant", evt.target.value)
              }
              inputProps={{
                name: "householdPregnant",
                id: "householdPregnant",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            error={error && (!familyCPS || familyCPS == "")}
          >
            <InputLabel shrink htmlFor="familyCPS">
              Are you currently working with Child Protective Services?
            </InputLabel>
            <InputLabel htmlFor="familyCPS">
              Are you currently working with Child Protective Services?
            </InputLabel>
            <Select
              native
              required
              value={familyCPS}
              onChange={(evt) => updateData("familyCPS", evt.target.value)}
              inputProps={{
                name: "familyCPS",
                id: "familyCPS",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            error={error && (!policeInvolvement || policeInvolvement == "")}
          >
            <InputLabel shrink htmlFor="policeInvolvement">
              Have you had police involvement in the last year, that resulted in
              an arrest or incarceration?
            </InputLabel>
            <InputLabel htmlFor="policeInvolvement">
              Have you had police involvement in the last year, that resulted in
              an arrest or incarceration?
            </InputLabel>
            <Select
              native
              required
              value={policeInvolvement}
              onChange={(evt) =>
                updateData("policeInvolvement", evt.target.value)
              }
              inputProps={{
                name: "policeInvolvement",
                id: "policeInvolvement",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <FormControl
            required
            fullWidth
            className={"MuiFormControl-marginNormal longText"}
            error={
              error && (!livingInCensusTracks || livingInCensusTracks == "")
            }
          >
            <InputLabel shrink htmlFor="livingInCensusTracks">
              Are you living in the following census tracks: 94612, 94603,
              94621, 94601, 94605, 94606, 94607
            </InputLabel>
            <InputLabel htmlFor="livingInCensusTracks">
              Are you living in the following census tracks: 94612, 94603,
              94621, 94601, 94605, 94606, 94607
            </InputLabel>
            <Select
              native
              required
              value={livingInCensusTracks}
              onChange={(evt) =>
                updateData("livingInCensusTracks", evt.target.value)
              }
              inputProps={{
                name: "livingInCensusTracks",
                id: "livingInCensusTracks",
              }}
            >
              <option aria-label="None" value="" p={0} />
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
