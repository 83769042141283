import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

// let files = {
//   id: { uploaded: false, name: "" },
//   lease: { uploaded: false, name: "" },
//   rentalPayments: { uploaded: false, name: "" },
//   incomeLossDoc: { uploaded: false, name: "" },
//   housingCrisisProof: { uploaded: false, name: "" },
//   landlordW9: { uploaded: false, name: "" },
// };

// const Missing = <HighlightOffIcon style={{ color: "red" }} />;
// const Uploaded = <CheckCircleOutlineIcon style={{ color: "green" }} />;

export default function LandlordForm(formData) {
  const updateData = (field, value) => {
    const data = formData;
    data.setFormData({ ...data.formData, [field]: value });
  };
  // const { files } = formData.fileData;
  const {
    id,
    lease,
    rentalPayments,
    incomeLossDoc,
    housingCrisisProof,
    landlordW9,
  } = formData.fileData;

  // console.log("files", formData, files);
  const handleFileUpload = (evt) => {
    console.log(
      "evt target name",
      evt.target.name,
      evt.currentTarget.name,
      evt.currentTarget.files
    );
    const { name } = evt.currentTarget;

    if (evt.currentTarget.files && evt.currentTarget.files.length > 0) {
      const keys = Object.keys(evt.currentTarget.files);
      const uploadedFiles = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];

        const file = evt.currentTarget.files[key];
        if (file.size / 1000 > 3999) {
          file.toUpload = false;
        } else {
          file.toUpload = true;
        }
        uploadedFiles.push(file);
      }
      const f = {
        ...formData.fileData,
        [name]: {
          files: uploadedFiles,
          name,
          uploaded: true,
        },
      };
      console.log("new f", name, f);
      formData.setFileData(f);
    }
  };

  console.log("fileData", formData.fileData);
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom style={{ color: "#666" }}>
        Documentation Upload
      </Typography>

      <Typography
        variant="h6"
        gutterBottom
        style={{
          color: "#666",
          fontSize: "0.9rem",
          textDecoration: "underline",
          fontWeight: 500,
        }}
      >
        Uploads are limited to 4MB per file
      </Typography>

      <Grid container spacing={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <List>
              <ListItem>
                <ListItemIcon>
                  {!id || !id.uploaded ? (
                    <HighlightOffIcon style={{ color: "red" }} />
                  ) : (
                    <CheckCircleOutlineIcon style={{ color: "green" }} />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Valid ID"
                  secondary={
                    id && id.files && id.files.length > 0 ? (
                      <span>
                        {id.files
                          .filter((f) => f.toUpload)
                          .map((idFile) => idFile.name)
                          .join(", ")}
                        {id.files.filter((f) => !f.toUpload).length > 0 ? (
                          <span style={{ color: "red" }}>
                            <br />
                            Not Uploaded (file too large):
                            {id.files
                              .filter((f) => !f.toUpload)
                              .map((idFile) => idFile.name)
                              .join(", ")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      "Not Yet Uploaded"
                    )
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    name={"id"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!lease || !lease.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="Current Lease Agreement or documentation of rent paid"
                  secondary={
                    lease && lease.files && lease.files.length > 0 ? (
                      <span>
                        {lease.files
                          .filter((f) => f.toUpload)
                          .map((idFile) => idFile.name)
                          .join(", ")}
                        {lease.files.filter((f) => !f.toUpload).length > 0 ? (
                          <span style={{ color: "red" }}>
                            <br />
                            Not Uploaded (file too large):
                            {lease.files
                              .filter((f) => !f.toUpload)
                              .map((idFile) => idFile.name)
                              .join(", ")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      "Not Yet Uploaded"
                    )
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"lease"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!incomeLossDoc || !incomeLossDoc.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="Documentation of income and income loss such as pay check stubs showing difference in amounts; application or screenshot to Unemployment Department; Two bank statements showing difference, Layoff Letter, etc."
                  secondary={
                    incomeLossDoc &&
                    incomeLossDoc.files &&
                    incomeLossDoc.files.length > 0 ? (
                      <span>
                        {incomeLossDoc.files
                          .filter((f) => f.toUpload)
                          .map((idFile) => idFile.name)
                          .join(", ")}
                        {incomeLossDoc.files.filter((f) => !f.toUpload).length >
                        0 ? (
                          <span style={{ color: "red" }}>
                            <br />
                            Not Uploaded (file too large):
                            {incomeLossDoc.files
                              .filter((f) => !f.toUpload)
                              .map((idFile) => idFile.name)
                              .join(", ")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      "Not Yet Uploaded"
                    )
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"incomeLossDoc"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!housingCrisisProof || !housingCrisisProof.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="Proof of Covid Related Housing Crisis"
                  secondary={
                    housingCrisisProof &&
                    housingCrisisProof.files &&
                    housingCrisisProof.files.length > 0 ? (
                      <span>
                        {housingCrisisProof.files
                          .filter((f) => f.toUpload)
                          .map((idFile) => idFile.name)
                          .join(", ")}
                        {housingCrisisProof.files.filter((f) => !f.toUpload)
                          .length > 0 ? (
                          <span style={{ color: "red" }}>
                            <br />
                            Not Uploaded (file too large):
                            {housingCrisisProof.files
                              .filter((f) => !f.toUpload)
                              .map((idFile) => idFile.name)
                              .join(", ")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      "Not Yet Uploaded"
                    )
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"housingCrisisProof"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem>
              {/* <ListItem>
                <ListItemIcon>
                  <ListItemIcon>
                    {!landlordW9 || !landlordW9.uploaded ? (
                      <HighlightOffIcon style={{ color: "red" }} />
                    ) : (
                      <CheckCircleOutlineIcon style={{ color: "green" }} />
                    )}
                  </ListItemIcon>
                </ListItemIcon>
                <ListItemText
                  primary="W9 from Landlord"
                  secondary={
                    landlordW9 &&
                    landlordW9.files &&
                    landlordW9.files.length > 0 ? (
                      <span>
                        {landlordW9.files
                          .filter((f) => f.toUpload)
                          .map((idFile) => idFile.name)
                          .join(", ")}
                        {landlordW9.files.filter((f) => !f.toUpload).length >
                        0 ? (
                          <span style={{ color: "red" }}>
                            <br />
                            Not Uploaded (file too large):
                            {landlordW9.files
                              .filter((f) => !f.toUpload)
                              .map((idFile) => idFile.name)
                              .join(", ")}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    ) : (
                      "Not Yet Uploaded"
                    )
                  }
                />
                <Button variant="contained" component="label">
                  Upload File
                  <input
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    name={"landlordW9"}
                    onChange={handleFileUpload}
                  />
                </Button>
              </ListItem> */}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
