import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Oakland from "./images/oakland.jpg";
import Fremont from "./images/cof.jpg";
import KFH from './images/kfh.png';
import SF from './images/sf.jpg';
import Hayward from "./images/hayward.jpg";
import KOH from "./images/koh.png";

const imgStyle = {
  height: 65,
  // maxWidth: "80%",
  objectFit: "cover",
  display: "block",
  margin: "0 auto",
  marginTop: 10,
  marginBottom: 10,
};

export default function SelectCity(data) {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateData = (field, value) => {
    data.setFormData({ ...data.formData, [field]: value });
  };

  const hCutoff =
    new Date().toUTCString() >=
    new Date(Date.UTC(2020, 4, 20, 1, 0, 0)).toUTCString();

  // console.log("data", data);

  const { whatCity, error } = data.formData;

  return (
    <React.Fragment>
      <Dialog
        open={open && whatCity == "Oakland2"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onBackdropClick={async () => {
          console.log("backdrop click");
          await updateData("whatCity", "");
          handleClose();
        }}
      >
        <DialogTitle id="alert-dialog-title">{"NOTICE"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Oakland residents living in subsidized housing where they pay a
            maximum of 30% of their income on rent are ineligible for the
            program at this time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              updateData("whatCity", "");
              handleClose();
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant="h6" gutterBottom style={{ color: "#444" }}>
        Select Your City of Residence to Begin
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <img style={imgStyle} src={Fremont} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img style={imgStyle} src={KFH} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img style={imgStyle} src={KOH} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <img style={imgStyle} src={SF} />
        </Grid>
        <Grid item xs={12} style={{ padding: 25 }}>
          <Typography id={"message"} style={{ color: "red" }}>&nbsp;</Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            fullWidth
            className={"MuiFormControl-marginNormal"}
            required
            error={error && (!whatCity || whatCity == "")}
          >
            <InputLabel shrink htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <InputLabel htmlFor="what-city">
              What city do you live in?
            </InputLabel>
            <Select
              native
              value={whatCity}
              onChange={(evt) => {
                console.log(evt.target);
                if (evt.target.value !== "Oakland") setOpen(true);
                if (
                  evt.target.value == "Hayward2" ||
                  (evt.target.value == "Emeryville" &&
                    new Date() >= new Date("06/16/2020"))
                ) {
                  // updateData("whatCity", null);
                  updateData("whatCity", evt.target.value);
                  document.getElementById(
                    "message"
                  ).textContent = `Our apologies but the Emeryville application portal has been closed due to the rent relief funding having been fully expended.`;
                } else {
                  document.getElementById("message").textContent = "";
                  updateData("whatCity", evt.target.value);
                  if (evt.target.value == "Oakland") document.location.href = "https://koh.bayareacs.org";
                  if (evt.target.value == "Fremont") document.location.href = "https://keepfremonthoused.bayareacs.org";
                }
              }}
              inputProps={{
                name: "whatCity",
                id: "what-city",
              }}
            >
              <option aria-label="None" value={""} />
              {/* <option value={"Emeryville"}>Emeryville</option> */}
              <option value={"Fremont"}>Fremont</option>
              {/* <option value={"Hayward"}>Hayward</option> */}
              <option value={"Oakland"}>Oakland</option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
