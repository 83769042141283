import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import DescriptionIcon from "@material-ui/icons/Description";

import Oakland from "./images/oakland.jpg";
import Emeryville from "./images/emeryville.png";
import Hayward from "./images/hayward.jpg";

const imgStyle = {
  height: 65,
  // maxWidth: "80%",
  objectFit: "cover",
  display: "block",
  margin: "0 auto",
  marginTop: 10,
  marginBottom: 10,
};

export default function Intro(formData) {
  const { whatCity } = formData.formData;

  const cityImage = () => {
    switch (whatCity) {
      case "Emeryville":
        return Emeryville;
      case "Hayward":
        return Hayward;
      case "Oakland":
        return Oakland;
      default:
        return "";
    }
  };

  return (
    <React.Fragment>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Emeryville} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Hayward} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={KOH} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <img style={imgStyle} src={Oakland} />
        </Grid>
      </Grid> */}
      <div>
        <img style={imgStyle} src={cityImage()} />
      </div>
      <Typography variant="h6" gutterBottom style={{ color: "#444" }}>
        Before You Begin
      </Typography>
      <Typography
        gutterBottom
        style={{ color: "#666", textDecoration: "underline" }}
      ></Typography>
      {whatCity != "Oakland" && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <a
              href={`${
                whatCity == "Emeryville"
                  ? "/attachments/ERAP Program Guidelines.pdf"
                  : whatCity == "Hayward"
                  ? "/attachments/Tenant Relief Program Guidelines.pdf"
                  : ""
              }`}
              target="_blank"
              style={{ fontSize: "1.5rem" }}
            >
              Please click here to review program eligibility requirements
            </a>
          </Grid>
        </Grid>
      )}

      <Typography
        style={{ color: "#666", textDecoration: "underline", marginTop: 25 }}
      >
        Please have the following documents ready to upload before starting the
        application process.
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <List>
            <ListItem>
              <ListItemIcon>
                <PermIdentityIcon />
              </ListItemIcon>
              <ListItemText
                primary="Valid ID"
                secondary={
                  "Any photo identification including Name, Date of Birth, etc."
                }
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Current Lease Agreement or documentation of rent paid" />
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Documentation of income and income loss such as pay check stubs showing difference in amounts; application or screenshot to Unemployment Department; Two bank statements showing difference, Layoff Letter, etc." />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Proof of covid related housing crisis: documentation certifying you behind on payments, inability to pay rent, etc."
                secondary={
                  "If you do not have, you will need to certify/attest you have a bonafied housing crisis and/or you do not have the ability to pay the next months’ rent."
                }
              />
            </ListItem>
            {/* <ListItem>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="W9 from Landlord / Property Owner"
                secondary={
                  <span>
                    {
                      "If you cannot submit with application, you will need to submit before final determination can be made.  Click "
                    }
                    <a
                      href={"https://www.irs.gov/pub/irs-pdf/fw9.pdf"}
                      target={"_blank"}
                    >
                      {"here"}
                    </a>
                    {" to download W-9 form to provide to your landlord."}
                  </span>
                }
              />
            </ListItem> */}
          </List>
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        gutterBottom
        style={{ color: "#666", paddingTop: 50, textAlign: "center" }}
      >
        Please note that applications do not save progress and must be submitted
        once complete.
      </Typography>
      <Typography
        variant="h6"
        gutterBottom
        style={{ color: "#666", paddingTop: 50 }}
      >
        If you have all of the above ready click the NEXT button to begin.
      </Typography>
    </React.Fragment>
  );
}
