import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
import LandlordForm from "./Landlord";
import SelectCityForm from "./SelectCityForm";
import UploadForm from "./UploadForm";
import SubmissionForm from "./SubmissionForm";
import IntroForm from "./IntroForm";
import axios from "axios";

import AllHome from "./images/allhome.png";
import BACS from "./images/bacs.png";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <div>
        <img src={BACS} height={40} style={{paddingRight: 10}} />
        <img src={AllHome} height={40} style={{paddingLeft: 10}} />        
      </div>
      <div>Regional Homeless Prevention Platform</div>
      <div>© 2021 Bay Area Community Services, Inc.  All Rights Reserved</div>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      //   width: "50vw",
      minWidth: 600,
      maxWidth: "95vw",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [
  "Select City",
  "Before You Begin",
  "Applicant Information",
  //   "Landlord Information",
  "Placement Questionnaire",
  "Documentation",
  "Submission",
];

function getStepContent(
  step,
  formData,
  setFormData,
  fileData,
  setFileData,
  isSubmitting,
  setSubmitting,
  errorFields,
  setErrorFields,suggestedAddress,setSuggestedAddress,selectedAddress,setSelectedAddress
) {
  switch (step) {
    case 0:
      return <SelectCityForm formData={formData} setFormData={setFormData} />;
    case 1:
      return (
        <IntroForm
          formData={formData}
          setFormData={setFormData}
          fileData={fileData}
        />
      );
    case 2:
      return (
        <AddressForm
          formData={formData}
          setFormData={setFormData}
          errorFields={errorFields}
          setErrorFields={setErrorFields}
          suggestedAddress={suggestedAddress}
          setSuggestedAddress={setSuggestedAddress}
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      );
    // case 1:
    //   return <LandlordForm formData={formData} setFormData={setFormData} />;
    case 3:
      return <LandlordForm formData={formData} setFormData={setFormData} />;
    case 4:
      return (
        <UploadForm
          formData={formData}
          setFormData={setFormData}
          fileData={fileData}
          setFileData={setFileData}
        />
      );
    case 5:
      return (
        <SubmissionForm
          formData={formData}
          setFormData={setFormData}
          fileData={fileData}
          setFileData={setFileData}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      );
    default:
      throw new Error("Unknown step");
  }
}

export default function Checkout() {
  const classes = useStyles();
  const [suggestedAddress, setSuggestedAddress] = React.useState({address: []});
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  const [errorFields, setErrorFields] = React.useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [fileData, setFileData] = React.useState({
    id: { uploaded: false, name: "", files: [] },
    lease: { uploaded: false, name: "", files: [] },
    rentalPayments: { uploaded: false, name: "", files: [] },
    incomeLossDoc: { uploaded: false, name: "", files: [] },
    housingCrisisProof: { uploaded: false, name: "", files: [] },
    landlordW9: { uploaded: false, name: "", files: [] },
  });
  const [formData, setFormData] = React.useState({
    error: false,
    bypassRequired: true,
    name: "",
    agreed: false,
    signedName: null,
    score: new Array(12).fill(0),
    amiCalculator: 1,
    submitError: false,
    submitErrorMessage: "",
    submittedBy: "MYSELF",
    dob: null,
    files: {
      id: { uploaded: false, name: "" },
      lease: { uploaded: false, name: "" },
      rentalPayments: { uploaded: false, name: "" },
      incomeLossDoc: { uploaded: false, name: "" },
      housingCrisisProof: { uploaded: false, name: "" },
      landlordW9: { uploaded: false, name: "" },
    },
    submissionID: Date.now()
      .toString()
      .match(/\d{3}(?=\d{2,3})|\d+/g)
      .join("-"),
  });

  let buttonEnabled = false;

  const handleSetSubmit = async (toSet) => {
    // console.log("setting submitting to ", toSet);
    await setSubmitting(toSet);
    return;
  };

  const handleNext = async (evt) => {

    if (formData.whatCity && formData.whatCity == "Oakland") document.location.href = "https://koh.bayareacs.org";
    if (formData.whatCity && formData.whatCity == "Fremont") document.location.href = "https://keepfremonthoused.bayareacs.org";
    await setFormData({ ...formData, error: false });
    if (
      formData.bypassRequired &&
      document.querySelectorAll(":required").length > 0 &&
      [].map
        .call(document.querySelectorAll(":required"), (el) => el.value)
        .indexOf("") >= 0
    ) {
      // console.log("missing required fields");
      const errorFields = document.querySelectorAll(":required");
      let firstError;
      for (var i = 0; i < errorFields.length; i++) {
        const eField = errorFields[i];
        if (eField.value == "") console.log("error field name", eField);
      }
      errorFields.forEach((e) =>
        !firstError && e.value == "" ? (firstError = e) : null
      );
      // console.log("errorFields", errorFields, firstError, formData);
      firstError.scrollIntoView();

      return await handleSetFormData({ ...formData, error: true });
    } else {
      // console.log("no error");
      await handleSetFormData({ ...formData, error: false });
    }

    try {
      document.getElementById("appHeader").scrollIntoView();
    } catch (err) {
      console.error("error scrolling to app Header");
    }
    let response;
    await setActiveStep(activeStep + 1);
    if (activeStep + 1 == steps.length) await handleSetSubmit(true);
    if (activeStep + 1 == steps.length) return await handleSubmission();
    // console.log("active step response", response);

    // setTimeout(async () => {
    //   // await handleSetFormData({ ...formData });
    //   console.log("formData", formData);
    // }, 150);
  };

  const handleSubmission = async () => {
    // await setSubmitting(true);
    // console.log("set submitting");
    try {
      const response = await axios.post(
        "https://housing.bayareacs.org/api/form/create",
        { formData },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("response", response);
      if (response.data && response.data.error) {
        return handleSetFormData({
          ...formData,
          submitError: true,
          submitErrorMessage: "Error in Submission\n\n" + response.data.error,
        });
      }
      await handleSetFormData({
        ...formData,
        formID: response.data.uploadResponse,
      });
      await handleFiles(response.data.submissionID);
      return response;
    } catch (e) {
      console.log("error in submit", e);
      handleSetFormData({
        ...formData,
        submitError: true,
        submitErrorMessage: "Error in Submission",
      });
    }
  };

  const handleFiles = async (formID) => {
    try {
      // console.log("handling files", fileData);
      const fileKeys = Object.keys(fileData);
      // console.log("fileKeys", fileKeys);
      const url = "https://housing.bayareacs.org/api/form/upload";

      // let files = [];

      for (var i = 0; i < fileKeys.length; i++) {
        const key = fileKeys[i];
        const files = fileData[key].files;
        // console.log("files", files, files.length);
        if (files)
          for (var fileCount = 0; fileCount < files.length; fileCount++) {
            const data = new FormData();
            data.append("submissionID", formID);
            // console.log("fileCount", fileCount);
            const file = files[fileCount];

            // console.log("file", file);
            if (file && file.name && file.toUpload) {
              // console.log("uploading file", file.name);
              // let f = formData.files[fileKeys[i]].file;
              data.append("file", file);
            }
            const config = {
              headers: {
                "content-type": "multipart/form-data",
              },
            };
            const fileSubmitRequest = await axios.post(url, data, config);
          }
      }
      // data.append("files", files);
      // console.log("file data", data);

      await handleSetSubmit(false);
      // console.log("submitting done");
      return true;
    } catch (e) {
      console.error("error in handle files", e);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  const handleSetFormData = async (data) => {
    // console.log("setting form data to new data", data);
    await setFormData(data);
    // console.log("updating formData", formData);
  };

  // window["forms"] = formData;

  const {
    agreed,
    signedName,
    bypassRequired,
    submitError,
    submitErrorMessage,
    whatCity,
  } = formData;

  // console.log("activeStep", activeStep, steps.length);
  // window["axios"] = axios;
  // window["formData"] = formData;
  // window["fileData"] = fileData;
  // window["handleFiles"] = handleFiles;
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <AppBar position="absolute" color="default" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Company name
          </Typography>
        </Toolbar>
      </AppBar> */}
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            align="center"
            style={{ fontWeight: 200 }}
          >
            Emergency Rental Assistance Application
          </Typography>
          <Stepper
            activeStep={activeStep}
            className={classes.stepper}
            orientation={window.innerWidth < 650 ? "vertical" : "horizontal"}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                {isSubmitting && !submitError && (
                  <div>
                    {" "}
                    <CircularProgress />{" "}
                    <div>Submitting Form - Please Wait</div>
                  </div>
                )}
                {!isSubmitting && !submitError && (
                  <div>
                    <Typography variant="h5" gutterBottom>
                      Application Submitted Successfully
                    </Typography>
                    <Typography>
                      Your Submission ID is: {formData.submissionID}
                    </Typography>
                    <Typography variant="subtitle1" style={{ marginTop: 10 }}>
                      Thank you for your application. A Representative will get
                      back to you.
                    </Typography>
                  </div>
                )}
                {submitError && (
                  <div>
                    <Typography style={{ color: "red" }} variant="h5">
                      {submitErrorMessage}
                    </Typography>
                  </div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(
                  activeStep,
                  formData,
                  handleSetFormData,
                  fileData,
                  setFileData,
                  isSubmitting,
                  setSubmitting,
                  errorFields,
                  setErrorFields,
                  suggestedAddress,
                  setSuggestedAddress,
                  selectedAddress,
                  setSelectedAddress
                )}
                <div className={classes.buttons}>
                  {activeStep !== -1 && (
                    <Button
                      disabled={activeStep <= 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={true}
                  >
                    {activeStep === steps.length - 1 ? "Submit" : "Next"}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
        <div
          style={{ paddingBottom: 25, cursor: "pointer" }}
          onClick={() =>
            handleSetFormData({ ...formData, bypassRequired: !bypassRequired })
          }
        >
          <Copyright />
        </div>
      </main>
    </React.Fragment>
  );
}
